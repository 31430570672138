<template>
  <div>
    <asideBar />
    <div class="mini-sidebar" :style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }">
      <div id="app">
        <div class="main-content flex-fill">
          <div class="placeholder"></div>
          <div id="content" class="container px-md-5 mt-4">
            <main class="content">
              <div class="content-wrap">
                <div class="content-layout">
                  <nav class="text-sm text-muted mb-3 mb-md-4">
                    <i class="iconfont icon-home mr-2"></i><a class="crumbs" href="index.html">首页</a><i class="px-2">/</i><a>最新资讯</a
                    ><i class="px-2">/</i><span>{{ details.title }}</span>
                  </nav>
                  <div class="panel">
                    <div class="panel-header mb-4">
                      <h2 class="h3 font-weight-bold mb-3">{{ details.title }}</h2>
                      <div class="d-flex flex-fill text-muted text-sm">
                        <span class="mr-3"
                          ><a :title="siteName"><i class="iconfont icon-user-circle mr-1"></i>{{ siteName }}</a></span
                        ><span class="mr-3"
                          ><a><i class="iconfont icon-classification mr-1"></i>最新资讯</a></span
                        >
                        <div class="flex-fill"></div>
                      </div>
                    </div>
                    <div class="panel-body single" v-html="details.content">
                      <!-- <p>
												在撰写网站文章时，插入图片是一种常见的做法，但是否需要插入图片以及插入图片是否对SEO排名有影响是一个值得探讨的问题。本文将详细介绍是否需要在文章中插入图片以及其对SEO排名的影响。
											</p>
											<h3>插入图片的益处</h3>
											<p>1、<strong>丰富内容</strong>：插入图片可以使文章内容更加丰富多彩，吸引读者的注意力，提升阅读体验。</p>
											<p>2、<strong>解释说明</strong>：有些内容可能需要通过图片来更好地解释和说明，比如数据图表、示意图等。</p>
											<p>3、<strong>增加可读性</strong>：插入图片可以使文章版面更加美观，提高文章的可读性，让读者更愿意停留在页面上。</p>
											<p>4、<strong>利于分享</strong>：在社交媒体等平台分享时，带有图片的文章更容易吸引用户点击和转发，从而增加流量。</p>
											<h3>图片对SEO排名的影响</h3>
											<p>
												1、<strong>丰富多样的内容</strong>：搜索引擎喜欢丰富多样的内容，包括文字、图片、视频等。插入图片可以使网页内容更加丰富，有利于提升排名。
											</p>
											<p>2、<strong>优化图片标签</strong>：合理设置图片标签（alt属性），能够增加图片的搜索曝光度，提高网站的整体排名。</p>
											<p>3、<strong>增加用户停留时间</strong>：有吸引力的图片可以增加用户在网页上停留的时间，降低跳出率，对SEO排名有积极影响。</p>
											<p>4、<strong>提升页面速度</strong>：适当压缩和优化图片可以减少页面加载时间，提高用户体验，有利于SEO排名。</p>
											<h3>缺少图片对SEO排名的影响</h3>
											<p>
												虽然插入图片有助于SEO排名，但缺少图片并不会直接影响排名。搜索引擎更关注页面的内容质量、关键词优化、用户体验等因素。因此，即使文章没有图片，只要内容丰富、优质，仍然可以获得较好的排名。
											</p>
											<h3>总结</h3>
											<p>
												插入图片对网站文章有诸多益处，但缺少图片并不会直接影响SEO排名。关键是要根据文章内容和需求来决定是否插入图片，并注意优化图片标签、压缩图片大小，以提升用户体验和SEO排名。
											</p> -->
                    </div>
                    <div class="tags my-2"></div>
                  </div>
                  <div class="near-navigation mt-2 py-2"></div>
                  <div class="block-header my-3 my-md-4">
                    <h4 class="block-title"><i class="io io-yinle" id="term-4"></i>猜你喜欢</h4>
                  </div>
                  <div class="row io-mx-n2">
                    <div class="url-card post-card col-1a col-sm-2a col-xl-3a mb-3 mb-md-4" v-for="item of list" :key="item.id">
                      <div class="card-post list-item url-body" @click="clickContent(item.id)">
                        <div class="url-content">
                          <div class="list-content py-0">
                            <div class="list-body">
                              <a class="post-name overflowClip_2"
                                ><strong>{{ item.title }}</strong></a
                              >
                            </div>
                            <div class="list-footer">
                              <div class="d-flex flex-fill align-items-center">
                                <div class="text-sm text-muted">{{ item.timeAgo }}</div>
                                <div class="flex-fill"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <asideDrawer />
  </div>
</template>

<script>
import asideBar from '@/views/layout/components/aside'
import asideDrawer from '@/views/layout/components/asideDrawer'
import { getNewsInfo, getNewsRand } from '@/api/http'
export default {
  components: {
    asideBar,
    asideDrawer
  },
  data() {
    return {
      id: '',
      details: {},
      list: [],
      siteName: localStorage.getItem('siteName')
    }
  },
  watch: {
    '$route.params.id': function(newId) {
      this.id = newId
      this.getDetails()
      this.getList()
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetails()
    this.getList()
  },
  methods: {
    async getDetails() {
      var query = {
        id: this.id
      }
      const data = await getNewsInfo(query)
      this.details = data.data.data
    },
    async getList() {
      const data = await getNewsRand()
      this.list = data.data.data
      const now = new Date().getTime()
      this.list.forEach((item) => {
        item.timeAgo = this.daysSinceTimestamp(item.createtime)
      })
      console.log(this.list)
    },
    async clickContent(val) {
      var query = {
        id: val
      }
      const data = await getNewsInfo(query)
      this.details = data.data.data
    },
    daysSinceTimestamp(timestamp) {
      const now = Math.floor(Date.now() / 1000) // 当前时间的时间戳（秒）
      const difference = now - timestamp // 时间差（秒）

      const minutes = Math.floor(difference / 60)
      const hours = Math.floor(difference / 3600)
      const days = Math.floor(difference / 86400)
      const months = Math.floor(days / 30)

      if (months > 0) {
        return `${months}月前`
      } else if (days > 0) {
        return `${days}天前`
      } else if (hours > 0) {
        return `${hours}小时前`
      } else if (minutes > 0) {
        return `${minutes}分钟前`
      } else {
        return '刚刚' // 小于1分钟
      }
    }
  }
}
</script>

<style>
@import url('../css/bootstrap.css');
@import url('../css/style.css');
</style>
